import { Heading, TimeUtil, useFeatureFlags } from 'cuenect-web-core'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentContainer,
  InnerContainer,
  LineContainer,
  Paragraph,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { ProgramContainer } from '../components/organisms/programContainer'
import { eventConfig } from '../config'
import { isPostEvent } from './../config'

import { Text } from '../components/atoms'
import { replaceLinkText } from '../utility/styleProps'
import eventsData from './../ioslatedAgendaBuild/events.json'

const ProgramPage = (params: PageParams) => {
  const {
    pageContext: { lang, program },
  } = params
  const [scroller, setScroller] = React.useState(null)

  const agendaProgram = program || 'stage'
  const { t } = useTranslation('program')
  const [loaded, setLoaded] = React.useState(false)

  const programMapping: { [index: string]: string } = {
    stage: `agenda-stage`,
    techmeetups: `tech-meetup-${lang}`,
    techmeetup: `tech-meetup-${lang}`,
    guidedtours: `guided-tour`,
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  return (
    <PageFrame
      {...params}
      onScrollerInit={elem => {
        setScroller(elem)
      }}
      pageName="program"
    >
      {loaded && scroller && (
        <ContentContainer>
          <InnerContainer width="100%">
            <LineContainer className="LineContainer">
              <Heading>
                {t(
                  `${
                    isPostEvent() ? 'toplinePostEvent' : 'topline'
                  }.${agendaProgram}`
                )}
              </Heading>
              {agendaProgram === 'techmeetups' && lang === 'de' && (
                <Paragraph spacing={{ bottom: 0.5 }}>
                  {parse(t('linkEnTechMeetups'))}
                </Paragraph>
              )}
              {/*  {agendaProgram === 'guidedtours' && (
                <Paragraph spacing={{ bottom: 0.5 }}>
                  {parse(t('subline.guidedtours'))}
                </Paragraph>
              )} 
              {agendaProgram === 'stage' &&
                !TimeUtil.getNowUtc().isAfter(
                  TimeUtil.getUtc('2021-04-15T17:57:00Z')
                ) && (
                  <Paragraph spacing={{ bottom: 0.5 }}>
                    {parse(t('subline.stage'))}
                  </Paragraph>
                )}*/}
            </LineContainer>
          </InnerContainer>
          <ProgramContainer
            scroller={scroller}
            source={eventConfig.sources[lang]}
            subdivision={agendaProgram}
            slug={programMapping[agendaProgram]}
            postEvent={isPostEvent()}
          />
        </ContentContainer>
      )}
    </PageFrame>
  )
}

export default ProgramPage
